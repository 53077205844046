<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card style="min-height: 45rem">
          <el-table :data="list" border>
            <el-table-column prop="id" label="编号"></el-table-column>
            <el-table-column label="排序" prop="sort"></el-table-column>
            <el-table-column label="名称" prop="title"></el-table-column>
            <el-table-column label="链接" prop="link"></el-table-column>
            <el-table-column label="操作" width="240" align="center">
              <template #header>
                <el-button @click="add(0)" type="primary">添加</el-button>
              </template>
              <template #default="s">
                <el-button @click="manageChildren(s.row)">管理子项</el-button>
                <el-button @click="editItem(s.row,0)" type="primary">编辑</el-button>
                <el-button @click="del(s.row)" type="danger">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="12" v-if="manageParentId > 0">
        <el-card style="min-height: 45rem">
          <div class="y-desc" style="font-size: initial;font-weight: 600;margin-bottom: 1rem">
            #{{manageParentId}}:{{list.filter(e=>e.id === manageParentId)[0].title}}
          </div>
          <el-table :data="children_list" border>
            <el-table-column prop="id" label="编号"></el-table-column>
            <el-table-column label="排序" prop="sort"></el-table-column>
            <el-table-column label="图片">
              <template v-slot="s">
                <img :src="s.row.icon | tomedia" style="height: 3rem;width: 3rem;border-radius: 3px">
              </template>
            </el-table-column>
            <el-table-column label="名称" prop="title"></el-table-column>
            <el-table-column label="链接" prop="link"></el-table-column>
            <el-table-column label="操作" align="center">
              <template #header>
                <el-button @click="add(manageParentId)" type="primary">添加</el-button>
              </template>
              <template #default="s">
                <el-button @click="editItem(s.row,manageParentId)" type="primary">编辑</el-button>
                <el-button @click="del(s.row)" type="danger">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog title="编辑" :visible.sync="editFormBox" @close="editFormClose">
      <el-form label-width="3rem" style="padding: 0 2rem">
        <el-form-item label="排序">
          <el-input-number v-model="form.sort"></el-input-number>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="链接">
          <YWebsiteChooseLink v-model="form.link"></YWebsiteChooseLink>
        </el-form-item>
        <template v-if="form.parent_id > 0">
          <el-form-item label="描述">
            <el-input v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item label="图标">
            <y-upload-img v-model="form.icon"></y-upload-img>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormBox = false">取 消</el-button>
        <el-button type="primary" @click="edit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import YUploadImg from "@/components/y-form/y-upload/y_upload_img";
import YWebsiteChooseLink from "@/components/website/yWebsiteChooseLink";

const EditFormTpl = {
  sort:0,
  title:"",
  link:"",
  parent_id:0,
  desc :"",
  icon:""
}

export default {
  name: "topNav",
  components:{
    YWebsiteChooseLink,
    YUploadImg

  },
  data() {
    return {
      list:[],
      form:{...EditFormTpl},
      editFormBox:false,
      children_list:[],

      manageParentId:0,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    add(parentId=0){
      this.editFormBox=true;
      this.manageParentId=parentId;
      this.form.parent_id = parentId;
    },
    manageChildren({id}){
      this.manageParentId = id;
      this.childrenLoad();
    },
    childrenLoad(){
      if (!this.manageParentId)return;
      this.children_list = [];
      this.$u.api.website.topNavAll({parent_id: this.manageParentId}).then(res=>{
        this.children_list = res;
      })
    },
    del(item){
      this.$u.api.website.topNavDel({id:item.id}).then(()=>{
        if (item.parent_id >= 0)this.manageParentId = 0
        this.$message.success("操作成功");
        this.editFormBox = false;
        this.load();
        this.childrenLoad();
      })
    },
    editItem(item,parentId=0){
      this.form = {...item};
      this.form.parent_id = parentId;
      this.editFormBox = true;
    },
    editFormClose(){
      this.form = {...EditFormTpl};
      this.editFormBox = false;
    },
    load() {
      this.$u.api.website.topNavAll({
        parent_id: 0,
      }).then(res=>{
        this.list = res;
      })
    },
    edit(){
      this.$u.api.website.topNavEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.editFormBox = false;
        this.load();
        this.childrenLoad();
      })
    },
  }
}
</script>

<style scoped>

</style>